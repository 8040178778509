import { useTheme } from "@mui/material/styles";

const IconAddStudent = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        fill={fill}
        d="M8.143 11.143a3.857 3.857 0 1 0 0-7.714 3.857 3.857 0 0 0 0 7.714Zm12-3a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0ZM1.714 14.786a1.929 1.929 0 0 1 1.929-1.929h9c.17 0 .334.023.49.064a6.41 6.41 0 0 0-1.99 4.65c0 .57.073 1.123.213 1.648-.835.304-1.888.495-3.213.495-6.429 0-6.429-4.5-6.429-4.5v-.428Zm21.429 2.786a5.572 5.572 0 1 1-11.144 0 5.572 5.572 0 0 1 11.144 0ZM18 14.143a.428.428 0 1 0-.857 0v3h-3a.429.429 0 0 0 0 .857h3v3A.429.429 0 0 0 18 21v-3h3a.429.429 0 1 0 0-.857h-3v-3Z"
      />
    </svg>
  );
};
export default IconAddStudent;
