import { useState } from "react";
import { Breadcrumb } from "@refinedev/mui";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { IconDownArrow } from "../../icons";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { useGetIdentity, useList } from "@refinedev/core";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import {
  AssesmentReport,
  AssesmentStudentReport,
  BatchReport,
  StudentReport,
} from "../../components/assesmentReport";
import PageHeading from "../../components/pageHeading";
import { IconDate } from "../../icons";

type IUser = {
  email: string;
};

export const StudentAssessmentShow = () => {
  const {
    control,
    formState: { errors },
  } = useForm();

  const { data: user } = useGetIdentity<IUser>();
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedAssesment, setSelectedAssesment] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  const { data: dataBatch } = useList({
    resource: "batches",
    meta: {
      populate: "*",
    },
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  const { data: dataStudents } = useList({
    resource: "students",
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id,
      },
    ],
  });

  const { data: dataAssetsments } = useList({
    resource: "assessments",
    pagination: {
      pageSize: 100,
    },
    meta: {
      populate: "*",
    },
    filters: [
      {
        field: "Class.id",
        operator: "eq",
        value: selectedBatch?.Class?.id,
      },
    ],
  });

  const { data: dataStudentAssetsments, isLoading } = useList({
    resource: "student-assessments",
    pagination: {
      pageSize: 100,
    },
    meta: {
      populate: "*",
    },
    filters: [
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id,
      },
      {
        field: "Assessment.id",
        operator: selectedAssesment?.id ? "eq" : "contains",
        value: selectedAssesment?.id || "",
      },
      {
        field: "Student.id",
        operator: selectedStudent?.id ? "eq" : "contains",
        value: selectedStudent?.id || "",
      },
      {
        field: "DateConducted",
        operator: "gte",
        value: startDate?.format("YYYY-MM-DD"),
      },
      {
        field: "DateConducted",
        operator: "lte",
        value: endDate?.format("YYYY-MM-DD"),
      },
    ],
  });

  const showReport = () => {
    if (selectedBatch?.id && selectedAssesment?.id && selectedStudent?.id) {
      return (
        <AssesmentStudentReport data={dataStudentAssetsments?.data || []} />
      );
    } else if (selectedBatch?.id && selectedAssesment?.id) {
      return (
        <AssesmentReport
          data={dataStudentAssetsments?.data || []}
          setSelectedStudent={setSelectedStudent}
        />
      );
    } else if (selectedBatch?.id && selectedStudent?.id) {
      return <StudentReport data={dataStudentAssetsments?.data || []} />;
    } else if (selectedBatch?.id) {
      return (
        <BatchReport
          data={dataStudentAssetsments?.data || []}
          setSelectedStudent={setSelectedStudent}
        />
      );
    }
  };

  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="Student Assesment" />
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        autoComplete="off"
      >
        <Grid container columnSpacing={6} rowSpacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl
              error={!!(errors as any)?.Batch}
              fullWidth
              sx={{
                ".MuiTextField-root": {
                  minWidth: "100% !important",
                },
                ".MuiStack-root": {
                  marginTop: "-8px !important",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    format="YYYY-MM-DD"
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(dayjs(newValue));
                    }}
                    slots={{
                      openPickerIcon: IconDate,
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              error={!!(errors as any)?.Batch}
              fullWidth
              sx={{
                ".MuiTextField-root": {
                  minWidth: "100% !important",
                },
                ".MuiStack-root": {
                  marginTop: "-8px !important",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    format="YYYY-MM-DD"
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(dayjs(newValue));
                    }}
                    slots={{
                      openPickerIcon: IconDate,
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Select Student Batch"
                control={control}
                rules={{
                  required: "This field is required",
                  validate: () => {
                    if (selectedBatch?.id === "")
                      return "Please select a batch";
                  },
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedBatch(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedBatch}
                    options={dataBatch?.data || []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student Batch"
                        variant="outlined"
                        error={!!errors.Batch?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>{(errors as any)?.Batch?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Assessment"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedAssesment(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedAssesment}
                    options={dataAssetsments?.data || []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assessment"
                        variant="outlined"
                        error={!!errors.Assetsment?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {(errors as any)?.Assetsment?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!!(errors as any)?.Student} fullWidth>
              <Controller
                name="Student"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedStudent(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedStudent}
                    options={dataStudents?.data || []}
                    getOptionLabel={(option) => option?.ChildName}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.ChildName}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Student"
                        variant="outlined"
                        error={!!errors.Assetsment?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {(errors as any)?.Assetsment?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          showReport()
        )}
      </Box>
    </Box>
  );
};
