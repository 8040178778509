import styled from "@emotion/styled";
import { Typography, Box } from "@mui/material";

const HomeBanner = () => {
  return (
    <BannerBox
      sx={{
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
        backgroundImage: "url('/assets/banner.png')",
        backgroundSize: "cover",
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        sx={{ fontWeight: "bold", fontSize: "26px !important", mb: "5px" }}
        align="center"
        variant="h1"
      >
        Welcome to Learning Initiatives for India
      </Typography>
      <Typography align="center">
        पढ़ेगा हर बच्चा, बढेगा हर बच्चा - Access. Learn. Retain. Educate
      </Typography>
      <Typography align="center">
        Every child thrives- Access. Learn. Retain. educate
      </Typography>
    </BannerBox>
  );
};

export default HomeBanner;

const BannerBox = styled(Box)`
  margin-top: -24px;
  margin-left: -24px;
  margin-right: -24px;
  width: calc(100% + 48px);
  padding-top: 140px;
  padding-bottom: 60px;

  @media (max-width: 1200px) {
    margin-top: -16px;
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
    padding-top: 120px;
    padding-bottom: 50px;
  }

  @media (max-width: 900px) {
    margin-top: -8px;
    margin-left: -8px;
    margin-right: -8px;
    width: calc(100% + 16px);
    padding-top: 100px;
    padding-bottom: 40px;
  }
`;
