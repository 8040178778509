import { useEffect, useState } from "react";
import { Breadcrumb } from "@refinedev/mui";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { useGetIdentity, useList } from "@refinedev/core";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import dayjs from "dayjs";

import { StudentReport, BatchReport } from "../../components/attendanceReport";
import { API_URL, TOKEN_KEY } from "../../constants";
import PageHeading from "../../components/pageHeading";
import { IconDate } from "../../icons";

type IUser = {
  email: string;
};

export const StudentAttendanceShow = () => {
  const {
    control,
    formState: { errors },
  } = useForm();

  const { data: user } = useGetIdentity<IUser>();
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [report, setReport] = useState(dayjs());

  const { data: dataBatch } = useList({
    resource: "batches",
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  const { data: dataStudents } = useList({
    resource: "students",
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id || "",
      },
    ],
  });

  const { data: dataAttendance } = useList({
    resource: "student-attendances",
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "Student.id",
        operator: "eq",
        value: selectedStudent?.id || "",
      },
      {
        field: "Date",
        operator: "gte",
        value: startDate?.format("YYYY-MM-DD"),
      },
      {
        field: "Date",
        operator: "lte",
        value: endDate?.format("YYYY-MM-DD"),
      },
    ],
  });

  const showAttendance = () => {
    if (selectedStudent) {
      return <StudentReport data={dataAttendance?.data} />;
    } else if (selectedBatch) {
      return (
        <BatchReport data={report} setSelectedStudent={setSelectedStudent} />
      );
    }
  };

  useEffect(() => {
    const url = `${API_URL}/api/student-attendance/report?student=${
      selectedStudent?.id || ""
    }&start_date=${startDate?.format("YYYY-MM-DD") || ""}&end_date=${
      endDate?.format("YYYY-MM-DD") || ""
    }&batch=${selectedBatch?.id}`;
    const getReport = async () => {
      const { data } = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });

      setReport(data?.data);
    };

    if (selectedBatch) {
      getReport();
    }
  }, [startDate, endDate, selectedBatch, selectedStudent]);

  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="Attendance Report" />
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container columnSpacing={6} rowSpacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl
              error={!!(errors as any)?.Batch}
              fullWidth
              sx={{
                ".MuiTextField-root": {
                  minWidth: "100% !important",
                },
                ".MuiStack-root": {
                  marginTop: "-8px !important",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    format="YYYY-MM-DD"
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(dayjs(newValue));
                    }}
                    sx={{
                      svg: { color: "#f8b864" },
                    }}
                    slots={{
                      openPickerIcon: IconDate,
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              error={!!(errors as any)?.Batch}
              fullWidth
              sx={{
                ".MuiTextField-root": {
                  minWidth: "100% !important",
                },
                ".MuiStack-root": {
                  marginTop: "-8px !important",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    format="YYYY-MM-DD"
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(dayjs(newValue));
                    }}
                    sx={{
                      svg: { color: "#f8b864" },
                    }}
                    slots={{
                      openPickerIcon: IconDate,
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Batch"
                control={control}
                rules={{
                  required: "This field is required",
                  validate: () => {
                    if (selectedBatch?.id === "")
                      return "Please select a batch";
                  },
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedBatch(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedBatch}
                    options={dataBatch?.data || []}
                    getOptionLabel={(option) => option?.Name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student Batch"
                        variant="outlined"
                        error={!!errors.Batch?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>{(errors as any)?.Batch?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!!(errors as any)?.Student} fullWidth>
              <Controller
                name="Student"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value?.id);
                      setSelectedStudent(value);
                    }}
                    options={dataStudents?.data || []}
                    getOptionLabel={(option) => option?.ChildName}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.ChildName}
                        </li>
                      );
                    }}
                    value={selectedStudent}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Student"
                        variant="outlined"
                        error={!!errors.category?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {(errors as any)?.Student?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {showAttendance()}
      </Box>
    </Box>
  );
};
