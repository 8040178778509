import { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Controller } from "react-hook-form";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { SubHeading } from "../subHeading";
import blurTarget from "../../utils/blurTarget";
import { IconDate, IconDownArrow } from "../../icons";

const RenderField = ({
  formData,
  register,
  errors,
  control,
  setValueValue,
}) => {
  const [value, setValue] = useState(null);
  const inputRef = useRef(null);

  if (inputRef.current) {
    inputRef.current.focus();
  }

  const fieldType = (type: string) => {
    if (type === "SmallText") {
      return "text";
    }
    if (type === "Number") {
      return "number";
    }
    if (type === "Email") {
      return "email";
    }
  };

  const renderField = (item: any) => {
    if (item?.FieldType === "SmallText") {
      return (
        <>
          {item.SectionTitle && <SubHeading title={item.SectionTitle} />}
          <TextField
            {...register(
              item?.Name,
              item?.Required
                ? {
                    required: "This field is required",
                  }
                : {}
            )}
            inputRef={inputRef}
            error={!!(errors as any)?.[item?.Name]}
            helperText={(errors as any)?.[item?.Name]?.message}
            margin="normal"
            fullWidth
            type={fieldType(item?.FieldType)}
            label={item?.Label}
            required={item?.Required}
            name={item?.Name}
          />
          {item?.Spacing && (
            <Box sx={{ mb: 3, pb: 3.5, borderBottom: "2px solid #999" }} />
          )}
        </>
      );
    }
    if (item?.FieldType === "Number") {
      return (
        <>
          {item.SectionTitle && <SubHeading title={item.SectionTitle} />}
          <TextField
            {...register(
              item?.Name,
              item?.Required
                ? {
                    required: "This field is required",
                    valueAsNumber: item?.FieldType === "Number",
                  }
                : {
                    valueAsNumber: item?.FieldType === "Number",
                  }
            )}
            error={!!(errors as any)?.[item?.Name]}
            helperText={(errors as any)?.[item?.Name]?.message}
            margin="normal"
            fullWidth
            type={fieldType(item?.FieldType)}
            label={item?.Label}
            required={item?.Required}
            name={item?.Name}
            onWheel={blurTarget}
            InputProps={{
              inputProps: { min: 0 },
            }}
            onKeyPress={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
          />
          {item?.Spacing && (
            <Box sx={{ mb: 3, pb: 3.5, borderBottom: "2px solid #999" }} />
          )}
        </>
      );
    }
    if (item?.FieldType === "Email") {
      return (
        <>
          {item.SectionTitle && <SubHeading title={item.SectionTitle} />}
          <TextField
            {...register(
              item?.Name,
              item?.Required
                ? {
                    required: "This field is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  }
                : {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  }
            )}
            error={!!(errors as any)?.[item?.Name]}
            helperText={(errors as any)?.[item?.Name]?.message}
            margin="normal"
            fullWidth
            type={fieldType(item?.FieldType)}
            label={item?.Label}
            name={item?.Name}
            required={item?.Required}
          />
          {item?.Spacing && (
            <Box sx={{ mb: 3, pb: 3.5, borderBottom: "2px solid #999" }} />
          )}
        </>
      );
    }
    if (item?.FieldType === "LargeText") {
      return (
        <>
          {item.SectionTitle && <SubHeading title={item.SectionTitle} />}
          <TextField
            {...register(
              item?.Name,
              item?.Required
                ? {
                    required: "This field is required",
                  }
                : {}
            )}
            error={!!(errors as any)?.[item?.Name]}
            helperText={(errors as any)?.[item?.Name]?.message}
            margin="normal"
            fullWidth
            type="text"
            label={item?.Label}
            name={item?.Name}
            multiline
            minRows={5}
            maxRows={5}
            required={item?.Required}
          />
          {item?.Spacing && (
            <Box sx={{ mb: 3, pb: 3.5, borderBottom: "2px solid #999" }} />
          )}
        </>
      );
    }
    if (item?.FieldType === "DropDown2") {
      return (
        <>
          {item.SectionTitle && <SubHeading title={item.SectionTitle} />}
          <FormControl
            error={!!(errors as any)?.[item?.Name]}
            sx={{ mt: 2, mb: 1 }}
            fullWidth
          >
            <InputLabel id={item?.Name}>{item?.Label}</InputLabel>
            <Select
              id={item?.Name}
              value={value}
              label={item?.Label}
              onChange={(event) => {
                setValue(event.target.value as string);
              }}
              required={item?.Required}
            >
              {item?.AllowedValues.split(",,")?.map((option: any) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{(errors as any)?.[item?.Name]}</FormHelperText>
          </FormControl>
          {item?.Spacing && (
            <Box sx={{ mb: 3, pb: 3.5, borderBottom: "2px solid #999" }} />
          )}
        </>
      );
    }
    if (item?.FieldType === "DropDown") {
      return (
        <>
          {item.SectionTitle && <SubHeading title={item.SectionTitle} />}
          <FormControl
            error={!!(errors as any)?.[item?.Name]}
            sx={{ mt: 2, mb: 1 }}
            fullWidth
          >
            <Controller
              rules={
                item?.Required ? { required: "This field is required" } : {}
              }
              name={item?.Name}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                    setValue(value);
                  }}
                  value={value}
                  options={item?.AllowedValues.split(",,")}
                  getOptionLabel={(option) => option}
                  popupIcon={<IconDownArrow />}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={uuidv4()}>
                        {option}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={item?.Label}
                      variant="outlined"
                      required={item?.Required}
                    />
                  )}
                />
              )}
            />
            <FormHelperText>
              {(errors as any)?.[item?.Name]?.message}
            </FormHelperText>
          </FormControl>
          {item?.Spacing && (
            <Box sx={{ mb: 3, pb: 3.5, borderBottom: "2px solid #999" }} />
          )}
        </>
      );
    }
    if (item?.FieldType === "Date") {
      return (
        <>
          {item.SectionTitle && <SubHeading title={item.SectionTitle} />}
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              sx={{
                ".MuiTextField-root": {
                  minWidth: "100% !important",
                },
                ".MuiStack-root": {
                  marginTop: "-8px !important",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    {...register(item?.Name)}
                    format="YYYY-MM-DD"
                    label={item.Label}
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                      setValueValue(
                        item?.Name,
                        dayjs(newValue as Date).format("YYYY-MM-DD")
                      );
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    slots={{
                      openPickerIcon: IconDate,
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {item?.Spacing && (
            <Box sx={{ mb: 3, pb: 3.5, borderBottom: "2px solid #999" }} />
          )}
        </>
      );
    }
  };
  return <Box key={uuidv4()}>{renderField(formData)}</Box>;
};

export default RenderField;
