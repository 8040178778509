import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import { v4 as uuidv4 } from "uuid";

import { SubHeading } from "../subHeading";
import { TableHeader } from "../tableHeader";
import { PieChartData } from "../pieChartData";
import { Pie2Colors } from "../../constants";

export const BatchReport = ({ data, setSelectedStudent }) => {
  const presentsCount =
    data?.length > 0 &&
    data?.reduce((total: number, student: any) => total + student.presents, 0);
  const totalCount =
    data?.length > 0 &&
    data?.reduce((total: number, student: any) => total + student.total, 0);
  const absentCount = totalCount - presentsCount;

  const attendancePieData = [
    {
      id: "Present",
      label: "Present",
      value: presentsCount,
      color: Pie2Colors[0],
    },
    {
      id: "Absent",
      label: "Absent",
      value: absentCount,
      color: Pie2Colors[1],
    },
  ];

  return (
    <Box>
      {data?.length > 0 ? (
        <Box>
          <SubHeading title="Attendance Report" />
          <PieChartData pieData={attendancePieData} Colors={Pie2Colors} />
          <SubHeading title="Detailed Report" />
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              border: "1px solid #999",
            }}
          >
            <Table
              sx={{ minWidth: 280 }}
              aria-label="attendance table"
              size="small"
            >
              <TableHeader headers={["Student Name", "Attendance", "View"]} />
              <TableBody
                sx={{
                  "& .MuiTableCell-root": {
                    fontWeight: 600,
                  },
                }}
              >
                {data?.map((student: any) => (
                  <TableRow key={uuidv4()}>
                    <TableCell component="th" scope="row">
                      {student?.child_name}
                    </TableCell>
                    <TableCell>
                      {(student?.presents / student?.total) * 100}&#37;
                    </TableCell>
                    <TableCell
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        setSelectedStudent({
                          id: student?.student_id,
                          ChildName: student?.child_name,
                        })
                      }
                    >
                      <Button
                        color="primary"
                        size="small"
                        sx={{
                          textTransform: "none",
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <SubHeading title="No Data Found" />
      )}
    </Box>
  );
};
