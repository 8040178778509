import React from "react";
import { useDataGrid, List } from "@refinedev/mui";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useGetIdentity, useNavigation } from "@refinedev/core";

import PageHeading from "../../components/pageHeading";

type IUser = {
  email: string;
};

export const StudentList = () => {
  const { data: user } = useGetIdentity<IUser>();
  const { show } = useNavigation();

  const { dataGridProps: dataStudents } = useDataGrid({
    resource: "students",
    meta: {
      populate: "*",
    },
    filters: {
      permanent: [
        {
          field: "Batch.Teacher.email",
          operator: "eq",
          value: user?.email,
        },
      ],
    },
  });

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "id",
        headerName: "Id",
        type: "number",
        minWidth: 16,
      },
      {
        field: "ChildName",
        flex: 1,
        headerName: "Child Name",
        minWidth: 200,
      },
      {
        field: "Batch.Name",
        flex: 1,
        headerName: "Batch",
        minWidth: 200,
        renderCell: function render({ row }) {
          return <>{row.Batch.Name}</>;
        },
      },
    ],
    []
  );

  return (
    <List
      headerProps={{
        sx: {
          display: "none",
        },
      }}
    >
      <PageHeading heading="Your Students" />
      <DataGrid
        {...dataStudents}
        columns={columns}
        autoHeight
        density="comfortable"
        onRowClick={(row) => {
          show("students", row.id);
        }}
        sx={{ cursor: "pointer" }}
      />
    </List>
  );
};
