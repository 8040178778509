import { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Breadcrumb } from "@refinedev/mui";
import { v4 as uuidv4 } from "uuid";
import { IconDownArrow } from "../../icons";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TextField,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { useGetIdentity, useList, useNotification } from "@refinedev/core";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { API_URL, TOKEN_KEY } from "../../constants";
import MarksCell from "../../components/marksCell";
import { TableHeader } from "../../components/tableHeader";
import PageHeading from "../../components/pageHeading";
import { IconDate } from "../../icons";

type IUser = {
  email: string;
};

export const StudentAssessmentCreate = () => {
  const {
    handleSubmit,
    refineCore: { formLoading },
    control,
    formState: { errors },
  } = useForm();

  const { data: user } = useGetIdentity<IUser>();
  const { open } = useNotification();
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedAssesment, setSelectedAssesment] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [date, setDate] = useState(null);

  const { data: dataBatch } = useList({
    resource: "batches",
    meta: {
      populate: "*",
    },
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  const { data: dataStudents } = useList({
    resource: "students",
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id,
      },
    ],
  });

  const { data: dataAssetsments } = useList({
    resource: "assessments",
    meta: {
      populate: "*",
    },
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "Subject.id",
        operator: "eq",
        value: selectedSubject?.id,
      },
      {
        field: "Class.id",
        operator: "eq",
        value: selectedBatch?.Class?.id,
      },
    ],
  });

  const marks = dataStudents?.data.map((row) => ({
    Student: row.id,
    Batch: selectedBatch?.id,
    DateConducted: dayjs(date).format("YYYY-MM-DD"),
    MarksObtained: 0,
    Assessment: selectedAssesment?.id,
  }));

  const onSubmit = () => {
    axios
      .post(`${API_URL}/api/student-assessment/bulkadd `, marks, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      })
      .then(() => {
        open?.({
          type: "success",
          message: "Assesment Saved successfully",
        });
        setSelectedBatch(null);
        setSelectedAssesment(null);
        setSelectedSubject(null);
        setDate(null);
      });
  };

  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="Enter Assesment Scores" />
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container columnSpacing={6} rowSpacing={3} alignItems="center">
          <Grid item xs={12}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Batch"
                control={control}
                rules={{
                  required: "This field is required",
                  validate: () => {
                    if (selectedBatch?.id === "")
                      return "Please select a batch";
                  },
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedBatch(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedBatch}
                    options={dataBatch?.data || []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student Batch"
                        variant="outlined"
                        error={!!errors.Batch?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>{(errors as any)?.Batch?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Subject"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedSubject(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedSubject}
                    options={selectedBatch?.Subjects || []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Subject"
                        variant="outlined"
                        error={!!errors.Assetsment?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {(errors as any)?.Assetsment?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              error={!!(errors as any)?.ConductedOn}
              fullWidth
              sx={{
                ".MuiTextField-root": {
                  minWidth: "100% !important",
                },
                ".MuiStack-root": {
                  marginTop: "-8px !important",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Conducted On"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!(errors as any)?.ConductedOn,
                      },
                    }}
                    slots={{
                      openPickerIcon: IconDate,
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Assessment"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedAssesment(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedAssesment}
                    options={dataAssetsments?.data || []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assessment"
                        variant="outlined"
                        error={!!errors.Assetsment?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {(errors as any)?.Assetsment?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {date && (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 280 }}
              aria-label="attendance table"
              size="small"
            >
              <TableHeader headers={["Student Name", "Status"]} />
              <TableBody>
                {selectedAssesment &&
                  selectedBatch &&
                  selectedSubject &&
                  dataStudents?.data.map((row) => (
                    <MarksCell
                      row={row}
                      key={row.id}
                      marks={marks}
                      selectedAssesment={selectedAssesment}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {selectedAssesment && selectedBatch && selectedSubject && date && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
              variant={formLoading ? "outlined" : "contained"}
              color="primary"
              type="submit"
              sx={{ width: 180 }}
            >
              {formLoading ? <CircularProgress size={24} /> : "Save Assesment"}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
