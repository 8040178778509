import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { IconDownArrow } from "../../icons";
import {
  Typography,
  Stack,
  Box,
  FormHelperText,
  Autocomplete,
  FormControl,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "@refinedev/react-hook-form";
import { useShow, useList, useGetIdentity, useCreate } from "@refinedev/core";
import { Breadcrumb, UrlField } from "@refinedev/mui";

import PageHeading from "../../components/pageHeading";

type IUser = {
  email: string;
};

export const LessonShow = () => {
  const {
    control,
    formState: { errors },
  } = useForm();

  const { data: user } = useGetIdentity<IUser>();
  const { mutate } = useCreate();

  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);

  const { data: dataBatch } = useList({
    resource: "batches",
    meta: {
      populate: "*",
    },
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  const { data: dataLesson } = useList({
    resource: "lessons",
    pagination: {
      pageSize: 1000,
    },
    filters: [
      {
        field: "Subject.id",
        operator: "eq",
        value: selectedSubject?.id,
      },
      {
        field: "Class.id",
        operator: "eq",
        value: selectedBatch?.Class?.id,
      },
    ],
  });

  const { queryResult } = useShow({
    resource: "lessons",
    meta: {
      populate: "*",
    },
    id: selectedLesson?.id,
  });
  const { data } = queryResult;

  const record = data?.data;

  const { data: dataCompleatedLesson } = useList({
    resource: "lesson-completions",
    liveMode: "auto",
    filters: [
      {
        field: "Lesson.id",
        operator: "eq",
        value: selectedLesson?.id,
      },
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id,
      },
    ],
  });

  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="View Lesson" />
      <Stack gap={1}>
        <FormControl
          error={!!(errors as any)?.Batch}
          sx={{ mt: 2, mb: 1 }}
          fullWidth
        >
          <Controller
            name="Batch"
            control={control}
            rules={{
              required: "This field is required",
              validate: () => {
                if (selectedBatch?.id === "") return "Please select a batch";
              },
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  setSelectedBatch(value);
                  field.onChange(value?.id);
                }}
                value={selectedBatch}
                options={dataBatch?.data ?? []}
                getOptionLabel={(option) => option?.Name}
                popupIcon={<IconDownArrow />}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={uuidv4()}>
                      {option?.Name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Student Batch"
                    variant="outlined"
                    error={!!errors.Batch}
                    required
                  />
                )}
              />
            )}
          />
          <FormHelperText>{(errors as any)?.Batch}</FormHelperText>
        </FormControl>
        <Grid
          container
          columnSpacing={6}
          rowSpacing={3}
          sx={{ marginBottom: "10px" }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <FormControl
              error={!!(errors as any)?.Batch}
              sx={{ mt: 2, mb: 1 }}
              fullWidth
            >
              <Controller
                name="Subject"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedSubject(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedSubject}
                    options={selectedBatch?.Subjects ?? []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Subject"
                        variant="outlined"
                        error={!!errors.Subject}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {(errors as any)?.Subject?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl
              error={!!(errors as any)?.Batch}
              sx={{ mt: 2, mb: 1 }}
              fullWidth
            >
              <Controller
                name="Lesson"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedLesson(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedLesson}
                    options={dataLesson?.data ?? []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Lesson"
                        variant="outlined"
                        error={!!errors.Subject?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {(errors as any)?.Subject?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {selectedLesson && (
          <>
            {record?.Description ? (
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  Lesson Plan
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  value={record?.Description ?? ""}
                  sx={{ width: "100%", marginBottom: "20px" }}
                />
              </Box>
            ) : null}
            <Grid
              container
              columnSpacing={6}
              rowSpacing={3}
              sx={{ marginBottom: "10px" }}
            >
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="body1" fontWeight="bold">
                  Student Assignment
                </Typography>
                <Box
                  sx={{
                    wordWrap: "break-word",
                    width: "100%",
                    border: "2px solid #ccc",
                    borderRadius: "5px",
                    padding: "10px 16px",
                  }}
                >
                  <UrlField
                    value={record?.StudentAssignment}
                    target="_blank"
                    rel="nnoopener noreferrer nofollow"
                    sx={{
                      a: {
                        wordBreak: "break-all",
                      },
                    }}
                  />
                </Box>
              </Grid>
              {record?.TrainingContent ? (
                <Grid item xs={12} md={6} lg={6}>
                  <Typography variant="body1" fontWeight="bold">
                    Training Content
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      border: "2px solid #ccc",
                      borderRadius: "5px",
                      padding: "10px 16px",
                    }}
                  >
                    <UrlField
                      value={record?.TrainingContent}
                      sx={{
                        a: {
                          wordBreak: "break-all",
                        },
                      }}
                    />
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </>
        )}
      </Stack>
      {selectedLesson && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color={
              dataCompleatedLesson?.data?.length > 0 ? "success" : "primary"
            }
            sx={{ height: 45, width: 250 }}
            onClick={() => {
              if (dataCompleatedLesson?.data?.length === 0) {
                mutate({
                  resource: "lesson-completions",
                  values: {
                    Batch: selectedBatch?.id,
                    Lesson: selectedLesson?.id,
                  },
                });
              }
            }}
          >
            {dataCompleatedLesson?.data?.length > 0
              ? "Lesson Completed"
              : "Mark Lesson as Completed"}
          </Button>
        </Box>
      )}
    </Box>
  );
};
