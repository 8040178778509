import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";

import { SubHeading } from "../subHeading";
import { TableHeader } from "../tableHeader";

export const AssesmentStudentReport = ({ data }) => {
  return (
    <Box>
      {data?.length > 0 ? (
        <Box>
          <SubHeading title="Detail Report" />
          <TableContainer
            component={Paper}
            sx={{ border: "1px solid #999" }}
            elevation={0}
          >
            <Table
              sx={{ minWidth: 280 }}
              aria-label="attendance table"
              size="small"
            >
              <TableHeader headers={["Student Name", "Result"]} />
              <TableBody
                sx={{
                  "& .MuiTableCell-root": {
                    fontWeight: 600,
                  },
                }}
              >
                {data?.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: "1px solid #999",
                      }}
                    >
                      {row?.Student?.ChildName}
                    </TableCell>
                    <TableCell>
                      {row?.MarksObtained}&#47;{row?.Assessment?.TotalMarks}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <SubHeading title="No Data Found" />
      )}
    </Box>
  );
};
