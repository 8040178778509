import { useTheme } from "@mui/material/styles";

const IconStudentAssesment = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m1.646 2.73 1.562 1.562 2.604-2.604M1.646 8.979l1.562 1.563 2.604-2.604m-4.166 7.291 1.562 1.563 2.604-2.605M9.458 3.25h9.375M9.458 9.5h9.375m-9.375 6.25h9.375"
      />
    </svg>
  );
};
export default IconStudentAssesment;
