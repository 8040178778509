import { useEffect, useState } from "react";
import { Create } from "@refinedev/mui";
import { Autocomplete, Box, FormControl, FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "@refinedev/react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Controller } from "react-hook-form";
import { useGetIdentity, useList } from "@refinedev/core";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import blurTarget from "../../utils/blurTarget";
import PageHeading from "../../components/pageHeading";
import { IconDate, IconDownArrow, IconDownArrow2 } from "../../icons";

type IUser = {
  email: string;
};

const relations = [
  {
    id: 1,
    name: "Father",
  },
  {
    id: 2,
    name: "Mother",
  },
  {
    id: 3,
    name: "Sibling",
  },
  {
    id: 4,
    name: "Guardian",
  },
];

export const ParentMeetCreate = () => {
  const { data: user } = useGetIdentity<IUser>();
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [date, setDate] = useState(null);
  const [relationship, setRelationship] = useState("");

  const { data: dataBatch } = useList({
    resource: "batches",
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  const { data: dataStudents } = useList({
    resource: "students",
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id,
      },
    ],
  });

  const {
    refineCore: { formLoading, onFinish },
    register,
    control,
    formState: { errors },
    setValue,
    resetField,
    handleSubmit,
    reset,
  } = useForm({
    refineCoreProps: {
      successNotification: () => {
        return {
          message: "Parent Meet created successfully",
          type: "success",
        };
      },
      onMutationSuccess: () => {
        reset();
        setSelectedBatch(null);
        setSelectedStudent(null);
        setDate(null);
        setRelationship("");
      },
    },
  });

  useEffect(() => {
    resetField("Student");
  }, [selectedBatch]);

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{ onClick: handleSubmit(onFinish) }}
      headerProps={{
        sx: {
          display: "none",
        },
      }}
      goBack={false}
      resource="parent-meets"
      wrapperProps={{
        sx: {
          boxShadow: 0,
        },
      }}
    >
      <PageHeading heading="Parent Meet" />
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Grid container columnSpacing={6} rowSpacing={3} alignItems="center">
          <Grid
            item
            xs={12}
            sx={{
              mb: 2,
            }}
          >
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Batch"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedBatch(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedBatch}
                    options={dataBatch?.data || []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student Batch"
                        variant="outlined"
                        error={!!errors.category?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>{(errors as any)?.Batch?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Student"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value?.id);
                      setSelectedStudent(value);
                    }}
                    options={dataStudents?.data || []}
                    getOptionLabel={(option) => option?.ChildName}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.ChildName}
                        </li>
                      );
                    }}
                    value={selectedStudent}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Student"
                        variant="outlined"
                        error={!!errors.category?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {(errors as any)?.Student?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              sx={{
                ".MuiTextField-root": {
                  minWidth: "100% !important",
                },
                ".MuiStack-root": {
                  marginTop: "-8px !important",
                },
              }}
              required
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    {...register("ConductedOn", {
                      required: "This field is required",
                    })}
                    label="Conducted On"
                    format="YYYY-MM-DD"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                      setValue(
                        "ConductedOn",
                        dayjs(newValue).format("YYYY-MM-DD")
                      );
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!(errors as any)?.ConductedOn,
                      },
                    }}
                    slots={{
                      openPickerIcon: IconDate,
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("GuardianName", {
                required: "This field is required",
                valueAsNumber: false,
              })}
              error={!!(errors as any)?.GuardianName}
              helperText={(errors as any)?.GuardianName?.message}
              margin="normal"
              fullWidth
              label="Guardian Name"
              required
              name="GuardianName"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              {...register("Relationship", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.Relationship}
              helperText={(errors as any)?.Relationship?.message}
              name="Relationship"
              margin="normal"
              fullWidth
              select
              label="Relationship"
              value={relationship}
              required
              onChange={(e) => setRelationship(e.target.value)}
              SelectProps={{
                IconComponent: IconDownArrow2,
              }}
            >
              {relations.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              {...register("GuardianPhoneNumber", {
                required: "This field is required",
                valueAsNumber: false,
              })}
              error={!!(errors as any)?.GuardianPhoneNumber}
              helperText={(errors as any)?.GuardianPhoneNumber?.message}
              margin="normal"
              fullWidth
              label="Guardian Phone Number"
              name="GuardianPhoneNumber"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              onWheel={blurTarget}
              onKeyPress={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("Comments", {
                required: "This field is required",
              })}
              error={!!(errors as any)?.Comments}
              helperText={(errors as any)?.Comments?.message}
              margin="normal"
              fullWidth
              label="Comment/ Discussion Notes"
              name="Comments"
              multiline
              minRows={5}
              maxRows={5}
              required
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
