import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";

import { SubHeading } from "../subHeading";
import { PieChartData } from "../pieChartData";
import { Pie2Colors } from "../../constants";
import { TableHeader } from "../tableHeader";

export const StudentReport = ({ data }) => {
  let presentTrueCount = 0;
  let presentFalseCount = 0;

  for (let i = 0; i < data.length; i++) {
    if (data[i].Present) {
      presentTrueCount++;
    } else {
      presentFalseCount++;
    }
  }

  const attendancePieData = [
    {
      id: "Present",
      label: "Present",
      value: presentTrueCount,
      color: Pie2Colors[0],
    },
    {
      id: "Absent",
      label: "Absent",
      value: presentFalseCount,
      color: Pie2Colors[1],
    },
  ];

  return (
    <Box>
      {data?.length > 0 ? (
        <Box>
          <SubHeading title="Attendance Report" />
          <PieChartData pieData={attendancePieData} Colors={Pie2Colors} />
          <SubHeading title="Detailed Report" />
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              border: "1px solid #999",
            }}
          >
            <Table
              sx={{ minWidth: 280 }}
              aria-label="attendance table"
              size="small"
            >
              <TableHeader headers={["Date", "Attendance"]} />
              <TableBody
                sx={{
                  "& .MuiTableCell-root": {
                    fontWeight: 600,
                  },
                }}
              >
                {data?.map((attendance: any) => (
                  <TableRow key={attendance.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: "1px solid #999",
                      }}
                    >
                      {attendance.Date}
                    </TableCell>
                    <TableCell>
                      {attendance.Present ? "Present" : "Absent"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <SubHeading title="No Data Found" />
      )}
    </Box>
  );
};
