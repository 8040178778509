import { useTheme } from "@mui/material/styles";

const IconViewLesson = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        fill={fill}
        d="M18.094 1.125V16.75a.781.781 0 0 1-.782.781H4.033a1.563 1.563 0 0 0-1.563 1.563H15.75a.781.781 0 1 1 0 1.562H1.687a.781.781 0 0 1-.78-.781V3.469A3.125 3.125 0 0 1 4.03.344h13.282a.781.781 0 0 1 .78.781Z"
      />
    </svg>
  );
};
export default IconViewLesson;
