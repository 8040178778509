import { useState } from "react";
import { TableCell, TableRow, TextField, Typography } from "@mui/material";
import blurTarget from "../../utils/blurTarget";

const MarksCell = ({ row, marks, selectedAssesment }) => {
  const [score, setScore] = useState("");

  const handleChange = (e: any) => {
    setScore(e.target.value);

    marks = marks.map((mark: any) => {
      if (mark.Student === row.id) {
        mark.MarksObtained = Number(e.target.value);
      }
      return mark;
    });
  };

  return (
    <TableRow key={row.id}>
      <TableCell
        sx={{
          borderRight: "1px solid #999",
          fontWeight: 600,
        }}
      >
        {row.ChildName}
      </TableCell>
      <TableCell
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          flexDirection: "row-reverse",
        }}
      >
        <Typography variant="body2" fontSize={20} fontWeight={600}>
          /{selectedAssesment?.TotalMarks}
        </Typography>
        <TextField
          sx={{ width: 100 }}
          id="outlined-basic"
          label="Score"
          variant="outlined"
          value={score}
          onChange={handleChange}
          type="number"
          InputProps={{
            inputProps: { min: 0, max: selectedAssesment?.TotalMarks },
          }}
          error={score > selectedAssesment?.TotalMarks}
          required
          onWheel={blurTarget}
          onKeyPress={(event) => {
            if (
              event?.key === "-" ||
              event?.key === "+" ||
              event?.key === "e" ||
              event?.key === "E"
            ) {
              event.preventDefault();
            }
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default MarksCell;
