import { useEffect, useState } from "react";
import { Create } from "@refinedev/mui";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useList, useGetIdentity } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import axios from "axios";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import MapFields from "../../components/mapFields";
import { API_URL, TOKEN_KEY } from "../../constants";
import { IconDownArrow } from "../../icons";
import PageHeading from "../../components/pageHeading";

type IUser = {
  email: string;
};

export const CreateStudent = () => {
  const { data: user } = useGetIdentity<IUser>();
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [formData, setFormData] = useState(null);
  const {
    refineCore: { formLoading, onFinish },
    register,
    setValue,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    refineCoreProps: {
      successNotification: () => {
        return {
          message: "Student added successfully",
          type: "success",
        };
      },
      onMutationSuccess: () => {
        reset();
        setSelectedSurvey(null);
      },
    },
  });

  const token = localStorage.getItem(TOKEN_KEY);

  const getFormData = async () => {
    const data = await axios.get(`${API_URL}/api/form-fields/2?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setFormData(data?.data?.data?.Fields);
  };

  const { data: dataSurveys, isLoading } = useList({
    resource: "surveys",
    pagination: {
      pageSize: 1000,
    },
    sorters: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  useEffect(() => {
    getFormData();
  }, []);

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{ onClick: handleSubmit(onFinish) }}
      headerProps={{
        sx: {
          display: "none",
        },
      }}
      goBack={false}
      resource="students"
      wrapperProps={{
        sx: {
          boxShadow: 0,
        },
      }}
    >
      <PageHeading heading="Add Student" />
      <FormControl
        error={!!(errors as any)?.Survey}
        sx={{ mt: 2, mb: 1 }}
        fullWidth
      >
        <Controller
          name="Survey"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
                setSelectedSurvey(value);
              }}
              loading={isLoading}
              options={dataSurveys?.data || []}
              getOptionLabel={(option) =>
                "Name: " +
                option?.FirstName +
                " " +
                option?.LastName +
                ", Address: " +
                option?.FullAddress
              }
              value={selectedSurvey}
              popupIcon={<IconDownArrow />}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={uuidv4()}>
                    {"Father Name: " +
                      option?.FirstName +
                      " " +
                      option?.LastName +
                      ", Address: " +
                      option?.FullAddress}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Survey"
                  variant="outlined"
                  required
                />
              )}
            />
          )}
        />
        <FormHelperText>{(errors as any)?.Survey?.message}</FormHelperText>
      </FormControl>
      <MapFields
        formData={formData}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
      />
    </Create>
  );
};
