import { TableCell, TableHead, TableRow } from "@mui/material";

export const TableHeader = ({ headers }) => {
  return (
    <TableHead
      sx={{
        backgroundColor: "#f8b864",
      }}
    >
      <TableRow>
        {headers?.map((header: any, index: any) => (
          <TableCell
            key={index}
            sx={{
              color: "#000",
              borderRight:
                index === headers.length - 1 ? "none" : "1px solid #eee",
            }}
          >
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
