import { Typography } from "@mui/material";

export const SubHeading = ({ title }) => {
  return (
    <Typography
      fontSize={22}
      variant="body1"
      sx={{ mt: 2, mb: 1 }}
      fontWeight={600}
    >
      {title}
    </Typography>
  );
};
