import { useTheme } from "@mui/material/styles";

const IconStudentHealth = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path fill={fill} d="M18.5 9h-2Zm-2 0h-2Zm0 0V7Zm0 0v2Z" />
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M18.5 9h-2m0 0h-2m2 0V7m0 2v2"
      />
      <path
        fill={fill}
        d="m12 5.574-.548.512a.75.75 0 0 0 1.096 0L12 5.574Zm-9.348 8.108a.75.75 0 1 0 1.283-.776l-1.283.776Zm3.885 2.489a.749.749 0 1 0-1.074 1.046l1.074-1.046ZM2.75 9.318c0-2.905 1.268-4.7 2.836-5.315 1.565-.613 3.754-.175 5.866 2.083l1.096-1.024c-2.388-2.554-5.199-3.36-7.509-2.456C2.732 3.51 1.25 5.992 1.25 9.318h1.5ZM15.51 19.96c1.493-1.211 3.281-2.834 4.703-4.647 1.407-1.794 2.537-3.879 2.537-5.997h-1.5c0 1.612-.88 3.364-2.218 5.071-1.324 1.689-3.016 3.232-4.466 4.408l.944 1.165Zm7.24-10.644c0-3.325-1.482-5.807-3.79-6.71-2.31-.905-5.12-.1-7.508 2.455l1.096 1.025c2.112-2.258 4.301-2.697 5.866-2.084 1.568.614 2.836 2.41 2.836 5.314h1.5ZM8.49 19.961c1.27 1.032 2.152 1.789 3.51 1.789v-1.5c-.723 0-1.173-.324-2.566-1.454l-.944 1.165Zm6.076-1.166c-1.393 1.13-1.843 1.455-2.566 1.455v1.5c1.358 0 2.241-.757 3.511-1.789l-.945-1.166Zm-10.63-5.889C3.187 11.67 2.75 10.455 2.75 9.318h-1.5c0 1.512.576 3 1.402 4.364l1.283-.776h.001Zm5.498 5.89a33.865 33.865 0 0 1-2.897-2.625l-1.074 1.046a35.338 35.338 0 0 0 3.027 2.744l.944-1.165Z"
      />
    </svg>
  );
};
export default IconStudentHealth;
