import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";

import { SubHeading } from "../subHeading";
import { PieChartData } from "../pieChartData";
import { Pie3Colors } from "../../constants";
import { TableHeader } from "../tableHeader";

export const AssesmentReport = ({ data, setSelectedStudent }) => {
  const overSeventy = data?.filter(
    (row: any) => (row?.MarksObtained / row?.Assessment?.TotalMarks) * 100 >= 70
  );
  const lessFourty = data?.filter(
    (row: any) => (row?.MarksObtained / row?.Assessment?.TotalMarks) * 100 < 40
  );
  const betweenFourtySeventy = data
    ?.filter(
      (row: any) =>
        (row?.MarksObtained / row?.Assessment?.TotalMarks) * 100 >= 40
    )
    ?.filter(
      (row: any) =>
        (row?.MarksObtained / row?.Assessment?.TotalMarks) * 100 < 70
    );

  const assesmentReport = [
    {
      id: 1,
      label: "More than 70%",
      value: overSeventy?.length,
      color: Pie3Colors[0],
    },
    {
      id: 2,
      label: "Between 40% to 70%",
      value: betweenFourtySeventy?.length,
      color: Pie3Colors[1],
    },
    {
      id: 3,
      label: "Less than 40%",
      value: lessFourty?.length,
      color: Pie3Colors[2],
    },
  ];

  return (
    <Box>
      {data?.length > 0 ? (
        <Box>
          <SubHeading title="Assesment Report" />
          <PieChartData pieData={assesmentReport} Colors={Pie3Colors} />
          <SubHeading title="Detail Report" />
          <TableContainer
            component={Paper}
            sx={{ border: "1px solid #999" }}
            elevation={0}
          >
            <Table
              sx={{ minWidth: 280 }}
              aria-label="attendance table"
              size="small"
            >
              <TableHeader headers={["Student Name", "Assessment", "Result"]} />
              <TableBody
                sx={{
                  "& .MuiTableCell-root": {
                    fontWeight: 600,
                  },
                }}
              >
                {data?.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: "1px solid #999",
                      }}
                    >
                      {row?.Student?.ChildName}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #999",
                      }}
                    >
                      {(
                        (row?.MarksObtained / row?.Assessment?.TotalMarks) *
                        100
                      )?.toFixed(2)}
                      &#37;&#40;{row?.MarksObtained}&#47;
                      {row?.Assessment?.TotalMarks}
                      &#41;
                    </TableCell>
                    <TableCell
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => setSelectedStudent(row?.Student)}
                    >
                      <Button
                        color="primary"
                        size="small"
                        sx={{
                          textTransform: "none",
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <SubHeading title="No Data Found" />
      )}
    </Box>
  );
};
