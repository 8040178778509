import { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Breadcrumb } from "@refinedev/mui";
import { v4 as uuidv4 } from "uuid";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TextField,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { useGetIdentity, useList, useNotification } from "@refinedev/core";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AttendanceCell from "../../components/attendanceCell";
import { API_URL, TOKEN_KEY } from "../../constants";
import { TableHeader } from "../../components/tableHeader";
import PageHeading from "../../components/pageHeading";
import { IconDate, IconDownArrow } from "../../icons";

type IUser = {
  email: string;
};

export const StudentAttendanceCreate = () => {
  const {
    handleSubmit,
    refineCore: { formLoading },
    control,
    formState: { errors },
  } = useForm();

  const { open } = useNotification();
  const { data: user } = useGetIdentity<IUser>();
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [date, setDate] = useState(dayjs());

  const { data: dataBatch } = useList({
    resource: "batches",
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  const { data: dataStudents } = useList({
    resource: "students",
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id,
      },
    ],
  });
  const attendance = dataStudents?.data.map((row) => ({
    Student: row?.id,
    Batch: selectedBatch?.id,
    Date: dayjs(date).format("YYYY-MM-DD"),
    Present: false,
  }));

  const onSubmit = () => {
    axios
      .post(`${API_URL}/api/student-attendance/bulkadd `, attendance, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      })
      .then(() => {
        open?.({
          type: "success",
          message: "Attendance Saved successfully",
        });
        setSelectedBatch(null);
        setDate(dayjs());
      });
  };

  return (
    <Box>
      <Breadcrumb />
      <PageHeading heading="Enter Attendance" />
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container columnSpacing={6} rowSpacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <FormControl
              error={!!(errors as any)?.ConductedOn}
              fullWidth
              sx={{
                ".MuiTextField-root": {
                  minWidth: "100% !important",
                },
                ".MuiStack-root": {
                  marginTop: "-8px !important",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    value={date}
                    label="Start Date"
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    sx={{
                      svg: { color: "#f8b864" },
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!(errors as any)?.ConductedOn,
                      },
                    }}
                    slots={{
                      openPickerIcon: IconDate,
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              error={!!(errors as any)?.Batch}
              sx={{ mt: 4, mb: 4 }}
              fullWidth
            >
              <Controller
                name="Batch"
                control={control}
                rules={{
                  required: "This field is required",
                  validate: () => {
                    if (selectedBatch?.id === "")
                      return "Please select a batch";
                  },
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedBatch(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedBatch}
                    options={dataBatch?.data || []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student Batch"
                        variant="outlined"
                        error={!!errors.Batch?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>{(errors as any)?.Batch?.message}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {selectedBatch && (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 280 }}
              aria-label="attendance table"
              size="small"
            >
              <TableHeader headers={["Student Name", "Status"]} />
              <TableBody>
                {selectedBatch &&
                  dataStudents?.data.map((row) => (
                    <AttendanceCell
                      row={row}
                      key={row?.id}
                      attendance={attendance}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {selectedBatch && dataStudents?.data.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Button
              variant={formLoading ? "outlined" : "contained"}
              color="primary"
              type="submit"
              sx={{ width: 180 }}
            >
              {formLoading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
