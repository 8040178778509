import { useTheme } from "@mui/material/styles";

const IconStudentsList = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        fill={fill}
        d="M8.143 12a3.856 3.856 0 1 0 0-7.712 3.856 3.856 0 0 0 0 7.712Zm6.514 6.53c.654.202 1.47.327 2.486.327 5.143 0 5.143-3.214 5.143-3.214a1.929 1.929 0 0 0-1.929-1.929h-5.705c.498.518.776 1.21.775 1.929v.476l-.003.07c-.018.287-.062.57-.132.85a5.212 5.212 0 0 1-.634 1.49v.002ZM20.143 9a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM1.714 15.643a1.929 1.929 0 0 1 1.929-1.929h9a1.929 1.929 0 0 1 1.928 1.929v.428s0 4.5-6.428 4.5c-6.429 0-6.429-4.5-6.429-4.5v-.428Z"
      />
    </svg>
  );
};
export default IconStudentsList;
