import { IResourceComponentsProps } from "@refinedev/core";
import { MuiShowInferencer } from "@refinedev/inferencer/mui";

export const StudentShow: React.FC<IResourceComponentsProps> = () => {
  return (
    <MuiShowInferencer
      fieldTransformer={(field: any) => {
        if (
          ["locale", "updatedAt", "createdAt", "publishedAt"].includes(
            field.key
          )
        ) {
          return false;
        }
        return field;
      }}
    />
  );
};
