import { useTheme } from "@mui/material/styles";

const IconStudentAttendance = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        fill={fill}
        d="m21.1 12.5 1.4 1.41-6.53 6.59L12.5 17l1.4-1.41 2.07 2.08 5.13-5.17ZM10 17l3 3H3v-2c0-2.21 3.58-4 8-4l1.89.11L10 17Zm1-13a4 4 0 1 1 0 8 4 4 0 0 1 0-8Z"
      />
    </svg>
  );
};
export default IconStudentAttendance;
