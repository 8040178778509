import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext } from "../../contexts";

interface TitleProps {
  collapsed: boolean;
}

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { mode } = useContext(ColorModeContext);

  return (
    <Link to="/">
      <Box
        sx={{
          height: "72px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "text.primary",
        }}
      >
        {collapsed ? (
          <img
            src={
              mode === "light" ? "/assets/lifi.png" : "/assets/lifi-dark.png"
            }
            alt="Lifi"
            style={{ width: "40px", height: "40px" }}
          />
        ) : (
          <img
            src={
              mode === "light"
                ? "/assets/lifi-full.png"
                : "/assets/lifi-full-dark.png"
            }
            alt="Lifi"
            style={{ height: "40px" }}
          />
        )}
      </Box>
    </Link>
  );
};
