import { useTheme } from "@mui/material/styles";

const IconReport = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <g clipPath="url(#a)">
        <path
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.5}
          d="M7 17v-6.25M12 17V7m5 10v-2.5M19.5 2h-15A2.5 2.5 0 0 0 2 4.5v15A2.5 2.5 0 0 0 4.5 22h15a2.5 2.5 0 0 0 2.5-2.5v-15A2.5 2.5 0 0 0 19.5 2Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconReport;
