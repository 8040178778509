import { useContext, useState } from "react";
import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Box } from "@mui/system";

import {
  AuthPage,
  ErrorComponent,
  notificationProvider,
  RefineSnackbarProvider,
  ThemedLayout,
  ThemedLayoutV2,
} from "@refinedev/mui";
import { CssBaseline, GlobalStyles } from "@mui/material";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { DataProvider } from "@refinedev/strapi-v4";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import {
  IconAddAttendance,
  IconAddStudent,
  IconAddSurvey,
  IconReport,
  IconCreate,
  IconDashboard,
  IconParentMeet,
  IconStudentActivity,
  IconStudentAssesment,
  IconStudentAttendance,
  IconStudentHealth,
  IconStudent,
  IconStudentsList,
  IconViewLesson,
} from "./icons";

import {
  CreateStudent,
  CreateSurvay,
  StudentList,
  StudentShow,
} from "./pages/students";
import { authProvider, axiosInstance } from "./authProvider";
import { Header } from "./components/header";
import { Title } from "./components/title";
import { API_URL, MIGRATION_FEATURE } from "./constants";
import { ColorModeContext, ColorModeContextProvider } from "./contexts";
import HomePage from "./pages/home";
import { StudentActivityCreate } from "./pages/student-activities";
import { LessonShow } from "./pages/lessons";
import { ParentMeetCreate } from "./pages/parent-meet";
import { StudentHealthCreate } from "./pages/student-health";
import {
  StudentAttendanceCreate,
  StudentAttendanceShow,
} from "./pages/student-attendance";
import {
  StudentAssessmentCreate,
  StudentAssessmentShow,
} from "./pages/student-assessment";
import {
  MigrationCreate,
  MigrationEdit,
  MigrationListAll,
  MigrationListRelevent,
  MigrationListYour,
} from "./pages/migration";

import "./styles/global.css";

function App() {
  const [showPassword, setShowPassword] = useState(false);
  const { mode } = useContext(ColorModeContext);

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider title="Lifi">
            <Refine
              authProvider={authProvider}
              dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
              notificationProvider={notificationProvider}
              routerProvider={routerBindings}
              resources={[
                {
                  name: "dashboard",
                  list: "/",
                  meta: {
                    icon: <IconDashboard />,
                    label: "Dashboard",
                  },
                },
                {
                  name: "students-families",
                  meta: {
                    label: "Student",
                    icon: <IconStudent />,
                  },
                },
                {
                  name: "surveys",
                  list: "/students/add-survey",
                  identifier: "surveys",
                  meta: {
                    icon: <IconAddSurvey />,
                    parent: "students-families",
                    label: "Add Survey",
                  },
                },
                {
                  name: "students",
                  identifier: "students",
                  list: "/students/add-student",
                  meta: {
                    parent: "students-families",
                    icon: <IconAddStudent />,
                    label: "Add Student",
                  },
                },
                {
                  name: "students-list",
                  list: "/students",
                  show: "/students/show/:id",
                  meta: {
                    parent: "students-families",
                    icon: <IconStudentsList />,
                    label: "Your Students",
                  },
                },
                {
                  name: "lessons-show",
                  list: "/lessons",
                  meta: {
                    icon: <IconViewLesson />,
                    label: "View Lesson",
                  },
                },
                {
                  name: "student-assessments",
                  meta: {
                    label: "Student Assessments",
                    icon: <IconStudentAssesment />,
                  },
                },
                {
                  name: "student-assessment-create",
                  list: "/student-assessment",
                  meta: {
                    label: "Enter Assessment",
                    parent: "student-assessments",
                    icon: <IconCreate />,
                  },
                },
                {
                  name: "student-assessments-report",
                  list: "/student-assessment/report",
                  meta: {
                    label: "Assessment Report",
                    parent: "student-assessments",
                    icon: <IconReport />,
                  },
                },
                {
                  name: "student-attendance",
                  meta: {
                    label: "Student Attendance",
                    icon: <IconStudentAttendance />,
                  },
                },
                {
                  name: "student-attendances-create",
                  list: "/student-attendance",
                  meta: {
                    label: "Enter Attendance",
                    parent: "student-attendance",
                    icon: <IconAddAttendance />,
                  },
                },
                {
                  name: "student-attendance-report",
                  list: "/student-attendance/report",
                  meta: {
                    label: "Attendance Report",
                    parent: "student-attendance",
                    icon: <IconReport />,
                  },
                },
                {
                  name: "student-activity",
                  meta: {
                    icon: <IconStudentActivity />,
                    label: "Student Activity",
                  },
                },
                {
                  name: "student-activities",
                  identifier: "/student-activities",
                  list: "/student-activity",
                  meta: {
                    icon: <IconCreate />,
                    label: "Create Activity",
                    parent: "student-activity",
                  },
                },
                {
                  name: "student-health",
                  meta: {
                    icon: <IconStudentHealth />,
                    label: "Student Health",
                  },
                },
                {
                  name: "student-healths",
                  identifier: "/student-healths",
                  list: "/student-health",
                  create: "/student-health",
                  meta: {
                    icon: <IconCreate />,
                    label: "Create",
                    parent: "student-health",
                  },
                },
                {
                  name: "parent-meet",
                  meta: {
                    icon: <IconParentMeet />,
                    label: "Parent Meet",
                  },
                },
                {
                  name: "parent-meets",
                  identifier: "/parent-meets",
                  list: "/parent-meet",
                  meta: {
                    icon: <IconCreate />,
                    label: "Create",
                    parent: "parent-meet",
                  },
                },
                // {
                //   name: "migration",
                //   meta: {
                //     icon: <IconStudent />,
                //     label: "Migrations",
                //   },
                // },
                // {
                //   name: "migrations",
                //   identifier: "/migration/new",
                //   list: "/migration/new",
                //   meta: {
                //     icon: <IconCreate />,
                //     label: "New",
                //     parent: "migration",
                //   },
                // },
                // {
                //   name: "migrations",
                //   identifier: "/migration",
                //   list: "/migration",
                //   edit: "/migration/edit/:id",
                //   meta: {
                //     icon: <IconStudentAssesment />,
                //     label: "All",
                //     parent: "migration",
                //   },
                // },
                // {
                //   name: "migrations",
                //   identifier: "/migration/your",
                //   list: "/migration/your",
                //   meta: {
                //     icon: <IconStudentAssesment />,
                //     label: "Your",
                //     parent: "migration",
                //   },
                // },
                // {
                //   name: "migrations",
                //   identifier: "/migration/relevent",
                //   list: "/migration/relevent",
                //   meta: {
                //     icon: <IconStudentAssesment />,
                //     label: "Relevent",
                //     parent: "migration",
                //   },
                // },
              ]}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
              }}
              Title={Title}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                      <ThemedLayout Header={Header}>
                        <Outlet />
                      </ThemedLayout>
                    </Authenticated>
                  }
                >
                  <Route index element={<HomePage />} />
                  <Route path="/students">
                    <Route index element={<StudentList />} />
                    <Route path="show/:id" element={<StudentShow />} />
                    <Route path="add-student" element={<CreateStudent />} />
                    <Route path="add-survey" element={<CreateSurvay />} />
                  </Route>
                  <Route path="/student-activity">
                    <Route index element={<StudentActivityCreate />} />
                  </Route>
                  <Route path="/lessons">
                    <Route index element={<LessonShow />} />
                  </Route>
                  <Route path="/student-attendance">
                    <Route index element={<StudentAttendanceCreate />} />
                    <Route path="report" element={<StudentAttendanceShow />} />
                  </Route>
                  <Route path="/student-assessment">
                    <Route index element={<StudentAssessmentCreate />} />
                    <Route path="report" element={<StudentAssessmentShow />} />
                  </Route>
                  <Route path="/student-health">
                    <Route index element={<StudentHealthCreate />} />
                  </Route>
                  <Route path="/parent-meet">
                    <Route index element={<ParentMeetCreate />} />
                  </Route>
                  {MIGRATION_FEATURE && (
                    <Route path="/migration">
                      <Route index element={<MigrationListAll />} />
                      <Route path="new" element={<MigrationCreate />} />
                      <Route path="your" element={<MigrationListYour />} />
                      <Route
                        path="relevent"
                        element={<MigrationListRelevent />}
                      />
                      <Route path="edit/:id" element={<MigrationEdit />} />
                    </Route>
                  )}
                </Route>
                <Route
                  element={
                    <Authenticated fallback={<Outlet />}>
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  <Route
                    path="/login"
                    element={
                      <AuthPage
                        type="login"
                        registerLink=""
                        rememberMe={false}
                        renderContent={(div: React.ReactNode) => {
                          return (
                            <Box
                              sx={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={
                                  mode === "light"
                                    ? "/assets/lifi-full.png"
                                    : "/assets/lifi-full-dark.png"
                                }
                                alt="Lifi"
                                style={{
                                  margin: "10px",
                                  height: "70px",
                                  alignSelf: "center",
                                }}
                              />
                              {div}
                              <Box
                                sx={{
                                  position: "absolute",
                                  right: "50px",
                                  top: "277px",
                                }}
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                  const password = document.getElementById(
                                    "password"
                                  ) as HTMLInputElement | null;
                                  showPassword
                                    ? (password.type = "text")
                                    : (password.type = "password");
                                }}
                              >
                                {showPassword ? (
                                  <AiFillEye color="#f8b864" />
                                ) : (
                                  <AiFillEyeInvisible color="#f8b864" />
                                )}
                              </Box>
                            </Box>
                          );
                        }}
                      />
                    }
                  />
                  <Route
                    path="/forgot-password"
                    element={
                      <AuthPage
                        type="forgotPassword"
                        renderContent={(div: React.ReactNode) => {
                          return (
                            <Box
                              sx={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={
                                  mode === "light"
                                    ? "/assets/lifi-full.png"
                                    : "/assets/lifi-full-dark.png"
                                }
                                alt="Lifi"
                                style={{
                                  margin: "10px",
                                  height: "70px",
                                  alignSelf: "center",
                                }}
                              />
                              {div}
                            </Box>
                          );
                        }}
                      />
                    }
                  />
                  <Route
                    path="/update-password"
                    element={
                      <AuthPage
                        type="updatePassword"
                        renderContent={(div: React.ReactNode) => {
                          return (
                            <Box
                              sx={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={
                                  mode === "light"
                                    ? "/assets/lifi-full.png"
                                    : "/assets/lifi-full-dark.png"
                                }
                                alt="Lifi"
                                style={{
                                  margin: "10px",
                                  height: "70px",
                                  alignSelf: "center",
                                }}
                              />
                              {div}
                            </Box>
                          );
                        }}
                      />
                    }
                  />
                </Route>
                <Route
                  element={
                    <Authenticated>
                      <ThemedLayoutV2 Header={Header} Title={Title}>
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
              </Routes>
              <RefineKbar />
              <UnsavedChangesNotifier />
            </Refine>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
