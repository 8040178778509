import { useTheme } from "@mui/material/styles";

const IconAddAttendance = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        fill={fill}
        d="M5.5 7a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0ZM8 3a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm7.5 5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM17 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM4.25 13A2.25 2.25 0 0 0 2 15.25v.278c.001.07.006.139.014.208.083.744.35 1.456.778 2.07C3.61 18.974 5.172 20 8 20c1.369 0 2.441-.24 3.276-.62a6.485 6.485 0 0 1-.268-1.55c-.645.38-1.595.67-3.008.67-2.422 0-3.486-.85-3.98-1.555a2.986 2.986 0 0 1-.52-1.438v-.257a.75.75 0 0 1 .75-.75h7.482c.249-.477.555-.919.909-1.317A2.243 2.243 0 0 0 11.75 13h-7.5ZM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Zm-2.146-2.354a.498.498 0 0 0-.708 0L16.5 18.793l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.498.498 0 0 0 .708 0l4-4a.498.498 0 0 0 0-.708Z"
      />
    </svg>
  );
};
export default IconAddAttendance;
