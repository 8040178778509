import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { v4 as uuidv4 } from "uuid";

export const PieChartData = ({ pieData, Colors }) => {
  const total = pieData?.reduce((acc: any, curr: any) => acc + curr.value, 0);
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentValue = (percent * 100).toFixed(0);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {percentValue}%
      </text>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mt: 1,
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          height: 200,
          width: 160,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={200} height={200}>
            <Pie
              data={pieData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {pieData.map((entry: any, index: any) => (
                <Cell
                  style={{ outline: "none" }}
                  key={`cell-${index}`}
                  fill={Colors[index % Colors.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ml: 2,
          gap: 1,
        }}
      >
        {pieData.map((label: any) => (
          <Box
            key={uuidv4()}
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            <Box>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: 1000,
                  backgroundColor: label.color,
                  mt: 0.5,
                }}
              />
            </Box>
            <Typography
              fontSize={16}
              variant="body1"
              sx={{ display: "flex" }}
              fontWeight={600}
            >
              {label.label} &#40;{((label.value / total) * 100).toFixed(2)}
              &#37;&#41;
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
