import { useEffect, useState } from "react";
import axios from "axios";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { useGetIdentity } from "@refinedev/core";

import MapFields from "../../components/mapFields";
import { API_URL, TOKEN_KEY } from "../../constants";
import PageHeading from "../../components/pageHeading";

type IUser = {
  email: string;
  id: string;
};

export const CreateSurvay = () => {
  const [formData, setFormData] = useState(null);
  const { data: user } = useGetIdentity<IUser>();

  const {
    refineCore: { formLoading, onFinish },
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    refineCoreProps: {
      successNotification: () => {
        return {
          message: "Survey Added Successfully",
          type: "success",
        };
      },
      onMutationSuccess: () => {
        reset();
        setValue("Teacher", user?.id);
      },
    },
  });

  const token = localStorage.getItem(TOKEN_KEY);

  const getFormData = async () => {
    const data = await axios.get(`${API_URL}/api/form-fields/1?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setFormData(data?.data?.data?.Fields);
    setValue("Teacher", user?.id);
  };

  useEffect(() => {
    getFormData();
  }, []);

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{ onClick: handleSubmit(onFinish) }}
      headerProps={{
        sx: {
          display: "none",
        },
      }}
      goBack={false}
      resource="surveys"
      wrapperProps={{
        sx: {
          boxShadow: 0,
        },
      }}
    >
      <PageHeading heading="Add Survey" />
      <MapFields
        formData={formData}
        register={register}
        errors={errors}
        control={control}
        setValue={setValue}
      />
    </Create>
  );
};
