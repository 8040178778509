import { useTheme } from "@mui/material/styles";

const IconStudentActivity = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M11 19a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v11Zm-4 0a1 1 0 1 1-2 0v-7a1 1 0 1 1 2 0v7Zm6 0v-9a1 1 0 0 1 2 0v9a1 1 0 0 1-2 0Zm4-14a1 1 0 0 1 2 0v14a1 1 0 0 1-2 0V5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default IconStudentActivity;
