import { useTheme } from "@mui/material/styles";
const IconAddSurvey = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path fill={fill} d="M3 1.5H1.5V21A1.5 1.5 0 0 0 3 22.5h19.5V21H3V1.5Z" />
      <path
        fill={fill}
        d="M22.5 6.75h-5.25v1.5h2.692l-5.692 5.693-3.217-3.226a.75.75 0 0 0-1.066 0L4.5 16.192l1.058 1.058 4.942-4.943 3.217 3.226a.75.75 0 0 0 1.066 0L21 9.306V12h1.5V6.75Z"
      />
    </svg>
  );
};
export default IconAddSurvey;
