import { useTheme } from "@mui/material/styles";

const IconDownArrow = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={10} fill="none">
      <path
        fill={fill}
        fillOpacity={0.8}
        d="m15.583 2.396-7 7.02a.736.736 0 0 1-.27.178.902.902 0 0 1-.313.052.929.929 0 0 1-.312-.052.722.722 0 0 1-.271-.177L.396 2.396a.99.99 0 0 1-.292-.73c0-.291.104-.541.313-.75a1 1 0 0 1 .729-.312 1 1 0 0 1 .73.313L8 7.042 14.125.917a.977.977 0 0 1 .72-.292c.284 0 .53.104.738.313a1 1 0 0 1 .313.729 1 1 0 0 1-.313.729Z"
      />
    </svg>
  );
};
export default IconDownArrow;
