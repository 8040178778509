import { useTheme } from "@mui/material/styles";

const IconParentMeet = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        fill={fill}
        d="M10.817 9.128c-.673.499-1.488.77-2.326.772a3.922 3.922 0 0 1-2.328-.772c-.562.468-1.123 1.14-1.582 1.967-1.067 1.922-1.185 3.894-.263 4.405.411.235.845.059 1.292-.371a7.881 7.881 0 0 0-.125 1.405c0 2.204.855 3.984 1.907 3.984.634 0 .949-.649 1.1-1.641.15.986.464 1.641 1.094 1.641 1.048 0 1.91-1.781 1.91-3.984 0-.495-.046-.967-.126-1.405.45.431.882.61 1.295.373.92-.511.8-2.482-.266-4.405-.457-.829-1.02-1.499-1.582-1.968v-.001ZM8.49 9.15a3.21 3.21 0 1 0-.002-6.424 3.21 3.21 0 0 0 .002 6.426V9.15Zm8 4.103a2.05 2.05 0 1 0-.002-4.1 2.05 2.05 0 0 0 .002 4.1Zm2.498 1.242a4.45 4.45 0 0 0-1.011-1.257c-.42.307-.93.495-1.488.495a2.5 2.5 0 0 1-1.487-.495c-.36.3-.718.729-1.012 1.258-.683 1.228-.758 2.488-.168 2.815.262.15.54.038.825-.237a5.166 5.166 0 0 0-.078.898c0 1.41.546 2.547 1.218 2.547.405 0 .608-.414.704-1.048.096.63.297 1.048.7 1.048.67 0 1.22-1.139 1.22-2.547 0-.315-.03-.618-.08-.897.287.275.564.39.828.238.586-.326.51-1.587-.171-2.815v-.002Z"
      />
    </svg>
  );
};
export default IconParentMeet;
