import { Box } from "@mui/system";

import DailyActivities from "../components/dailyActivities";

import {
  IconViewLesson,
  IconAddAttendance,
  IconStudentAssesment,
  IconStudentActivity,
  IconStudentHealth,
  IconParentMeet,
  IconAddStudent,
  IconStudentAttendance,
  IconAddSurvey,
} from "../icons";
import HomeBanner from "../components/homeBanner";

const HomePage = () => {
  const data = [
    {
      slug: "/student-attendance",
      heading: "Enter Attendance",
      icon: <IconAddAttendance />,
    },
    {
      slug: "/lessons",
      heading: "View Lesson Plans",
      icon: <IconViewLesson />,
    },
    {
      slug: "/student-assessment",
      heading: "Enter Assessment",
      icon: <IconStudentAssesment />,
    },
    {
      slug: "/student-activity",
      heading: "Student Activity",
      icon: <IconStudentActivity />,
    },
  ];

  const weeklydata = [
    {
      slug: "/student-health",
      heading: "Student Health",
      icon: <IconStudentHealth />,
    },
    {
      slug: "/parent-meet",
      heading: "Parent Meet",
      icon: <IconParentMeet />,
    },
    {
      slug: "/students/add-student",
      heading: "Add Student",
      icon: <IconAddStudent />,
    },
  ];

  const report = [
    {
      slug: "/student-attendance/report",
      heading: "Attendance",
      icon: <IconStudentAttendance />,
    },
    {
      slug: "/student-assessment/report",
      heading: "Assessment",
      icon: <IconAddSurvey />,
    },
  ];
  return (
    <Box>
      <HomeBanner />
      <DailyActivities
        title="Daily Activities"
        items={data}
        borderbottom={true}
      />
      <DailyActivities
        title="Weekly/Monthly Activities"
        items={weeklydata}
        borderbottom={true}
      />
      <DailyActivities title="Reports" items={report} borderbottom={false} />
    </Box>
  );
};

export default HomePage;
