import { v4 as uuidv4 } from "uuid";
import { Box } from "@mui/material";
import RenderField from "../renderField";

const MapFields = ({ formData, register, errors, control, setValue }) => {
  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      {formData?.map((data: Object) => (
        <RenderField
          formData={data}
          key={uuidv4()}
          register={register}
          errors={errors}
          control={control}
          setValueValue={setValue}
        />
      ))}
    </Box>
  );
};

export default MapFields;
