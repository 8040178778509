import { useState } from "react";
import { Create } from "@refinedev/mui";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { IconFileUpload } from "../../icons";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconDownArrow } from "../../icons";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Input,
  Stack,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { useGetIdentity, useList } from "@refinedev/core";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";

import PageHeading from "../../components/pageHeading";
import { API_URL, TOKEN_KEY } from "../../constants";

type IUser = {
  email: string;
};

export const StudentActivityCreate = () => {
  const { data: user } = useGetIdentity<IUser>();
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [imageURL, setImageURL] = useState([]);
  const [imageId, setImageId] = useState([]);

  const {
    refineCore: { formLoading, onFinish },
    register,
    control,
    formState: { errors },
    setValue,
    setError,
    handleSubmit,
    reset,
  } = useForm({
    refineCoreProps: {
      successNotification: () => {
        return {
          message: "Activity created successfully",
          type: "success",
        };
      },
      onMutationSuccess: () => {
        reset();
        setSelectedBatch(null);
        setSelectedStudent(null);
        setSelectedSubject(null);
        setSelectedLesson(null);
        setImageURL([]);
        setImageId([]);
      },
    },
  });

  const { data: dataBatch } = useList({
    resource: "batches",
    meta: {
      populate: "*",
    },
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  const { data: dataLesson } = useList({
    resource: "lessons",
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "Subject.id",
        operator: "eq",
        value: selectedSubject?.id,
      },
      {
        field: "Class.id",
        operator: "eq",
        value: selectedBatch?.Class?.id,
      },
    ],
  });

  const { data: dataStudents } = useList({
    resource: "students",
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id,
      },
    ],
  });

  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setIsUploadLoading(true);

      const formData = new FormData();

      const target = event.target;
      const file: File = (target.files as FileList)[0];

      formData.append("files", file);

      const res = await axios.post(`${API_URL}/api/upload`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });

      setImageURL([...imageURL, `${API_URL}${res.data[0].url}`]);
      setImageId([...imageId, res.data[0].id]);
      setValue("Media", imageId, { shouldValidate: true });

      setIsUploadLoading(false);
    } catch (error) {
      setError("Media", { message: "Upload failed. Please try again." });
      setIsUploadLoading(false);
    }
  };

  const onFinishHandler = (data: any) => {
    onFinish({
      ...data,
      Date: dayjs().format("YYYY-MM-DD"),
    });
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{ onClick: handleSubmit(onFinishHandler) }}
      headerProps={{
        sx: {
          display: "none",
        },
      }}
      goBack={false}
      resource="student-activities"
      wrapperProps={{
        sx: {
          boxShadow: 0,
        },
      }}
    >
      <PageHeading heading="Student Activity" />
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("Title", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.Title}
          helperText={(errors as any)?.Title?.message}
          margin="normal"
          fullWidth
          type="text"
          label="Activity Title"
          name="Title"
          required
        />
        <FormControl
          error={!!(errors as any)?.Batch}
          sx={{ mt: 2, mb: 1 }}
          fullWidth
        >
          <Controller
            name="Batch"
            control={control}
            rules={{
              required: "This field is required",
              validate: () => {
                if (selectedBatch?.id === "") return "Please select a batch";
              },
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  setSelectedBatch(value);
                  field.onChange(value?.id);
                }}
                options={dataBatch?.data || []}
                getOptionLabel={(option) => option?.Name}
                popupIcon={<IconDownArrow />}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={uuidv4()}>
                      {option?.Name}
                    </li>
                  );
                }}
                value={selectedBatch}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Student Batch"
                    variant="outlined"
                    error={!!errors.category?.message}
                    required
                  />
                )}
              />
            )}
          />
          <FormHelperText>{(errors as any)?.Batch?.message}</FormHelperText>
        </FormControl>
        <Grid
          container
          columnSpacing={6}
          rowSpacing={3}
          sx={{ marginBottom: "10px" }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <FormControl
              error={!!(errors as any)?.Batch}
              sx={{ mt: 2, mb: 1 }}
              fullWidth
            >
              <Controller
                name="Subject"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedSubject(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedSubject}
                    options={selectedBatch?.Subjects ?? []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Subject"
                        variant="outlined"
                        error={!!errors.Subject?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {(errors as any)?.Subject?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl
              error={!!(errors as any)?.Batch}
              sx={{ mt: 2, mb: 1 }}
              fullWidth
            >
              <Controller
                name="Lesson"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedLesson(value);
                      field.onChange(value?.id);
                    }}
                    value={selectedLesson}
                    options={dataLesson?.data ?? []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Lesson"
                        variant="outlined"
                        error={!!errors.Subject?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {(errors as any)?.Subject?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <FormControl
          error={!!(errors as any)?.Student}
          sx={{ mt: 2, mb: 1 }}
          fullWidth
        >
          <Controller
            name="Student"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  field.onChange(value?.id);
                  setSelectedStudent(value);
                }}
                options={dataStudents?.data || []}
                getOptionLabel={(option) => option?.ChildName}
                popupIcon={<IconDownArrow />}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={uuidv4()}>
                      {option?.ChildName}
                    </li>
                  );
                }}
                value={selectedStudent}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Student"
                    variant="outlined"
                    error={!!errors.category?.message}
                    required
                  />
                )}
              />
            )}
          />
          <FormHelperText>{(errors as any)?.Student?.message}</FormHelperText>
        </FormControl>

        <TextField
          {...register("Description", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.Description}
          helperText={(errors as any)?.Description?.message}
          margin="normal"
          fullWidth
          type="text"
          label="Activity Description"
          name="Description"
          multiline
          minRows={5}
          maxRows={5}
          required
        />
        <Stack
          direction="column"
          gap={2}
          flexWrap="wrap"
          sx={{ marginTop: "16px" }}
        >
          <label htmlFor="images-input">
            <Input
              id="images-input"
              type="file"
              sx={{ display: "none" }}
              onChange={onChangeHandler}
            />
            <input id="file" type="hidden" />
            <LoadingButton
              loading={isUploadLoading}
              loadingPosition="end"
              endIcon={<IconFileUpload />}
              variant="contained"
              component="span"
              disableElevation
              sx={{
                textTransform: "none",
              }}
            >
              Upload Image
            </LoadingButton>
            <br />
            {errors.Media && (
              <Typography variant="caption" color="#fa541c">
                {errors.Media?.message?.toString()}
              </Typography>
            )}
          </label>
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            {imageURL.map((url, index) => (
              <Box
                sx={{
                  position: "relative",
                }}
                key={uuidv4()}
              >
                <Box
                  key={index}
                  component="img"
                  sx={{
                    maxWidth: 250,
                    maxHeight: 250,
                  }}
                  src={url}
                  alt="Post image"
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "#fff",
                  }}
                  onClick={() => {
                    setImageURL(imageURL.filter((_, i) => i !== index));
                    setImageId(imageId.filter((_, i) => i !== index));
                  }}
                >
                  <DeleteIcon
                    sx={{
                      fontSize: 18,
                    }}
                  />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Stack>
      </Box>
    </Create>
  );
};
