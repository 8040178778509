import { Switch, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";

const AttendanceCell = ({ row, attendance }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (e: any) => {
    setChecked(e.target.checked);

    if (e.target.checked) {
      attendance = attendance.find((a: any) => a?.Student === row?.id).Present =
        true;
    }
  };

  return (
    <TableRow key={row.id}>
      <TableCell
        sx={{
          borderRight: "1px solid #999",
        }}
      >
        {row.ChildName}
      </TableCell>
      <TableCell>
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </TableCell>
    </TableRow>
  );
};

export default AttendanceCell;
