import { useState } from "react";
import { Create } from "@refinedev/mui";
import {
  Autocomplete,
  Box,
  TextField,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { IconDownArrow } from "../../icons";
import { useForm } from "@refinedev/react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { Controller } from "react-hook-form";
import { useGetIdentity, useList } from "@refinedev/core";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import blurTarget from "../../utils/blurTarget";
import PageHeading from "../../components/pageHeading";
import { IconDate } from "../../icons";

type IUser = {
  email: string;
};

export const StudentHealthCreate = () => {
  const { data: user } = useGetIdentity<IUser>();
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [date, setDate] = useState(dayjs());

  const { data: dataBatch } = useList({
    resource: "batches",
    filters: [
      {
        field: "Teacher.email",
        operator: "eq",
        value: user?.email,
      },
    ],
  });

  const { data: dataStudents } = useList({
    resource: "students",
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "Batch.id",
        operator: "eq",
        value: selectedBatch?.id,
      },
    ],
  });

  const {
    refineCore: { formLoading, onFinish },
    register,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    refineCoreProps: {
      successNotification: () => {
        return {
          message: "Student health added successfully",
          type: "success",
        };
      },
      onMutationSuccess: () => {
        reset();
        setSelectedBatch(null);
        setSelectedStudent(null);
        setDate(dayjs());
      },
    },
  });

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{ onClick: handleSubmit(onFinish) }}
      resource="student-healths"
      headerProps={{
        sx: {
          display: "none",
        },
      }}
      goBack={false}
      wrapperProps={{
        sx: {
          boxShadow: 0,
        },
      }}
    >
      <PageHeading heading="Student Health" />
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        autoComplete="off"
      >
        <Grid container columnSpacing={6} rowSpacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl error={!!(errors as any)?.Batch} fullWidth>
              <Controller
                name="Batch"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      setSelectedBatch(value);
                      field.onChange(value?.id);
                    }}
                    options={dataBatch?.data ?? []}
                    getOptionLabel={(option) => option?.Name}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.Name}
                        </li>
                      );
                    }}
                    value={selectedBatch}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student Batch"
                        variant="outlined"
                        error={!!errors.category?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>{(errors as any)?.Batch?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              sx={{
                ".MuiTextField-root": {
                  minWidth: "100% !important",
                },
                ".MuiStack-root": {
                  marginTop: "-8px !important",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    {...register("ConductedOn", {
                      required: "This field is required",
                    })}
                    format="YYYY-MM-DD"
                    label="Conducted On"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                      setValue(
                        "ConductedOn",
                        dayjs(newValue).format("YYYY-MM-DD")
                      );
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!(errors as any)?.ConductedOn,
                      },
                    }}
                    slots={{
                      openPickerIcon: IconDate,
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              error={!!(errors as any)?.Batch}
              sx={{ mt: 2, mb: 1 }}
              fullWidth
            >
              <Controller
                name="student"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value?.id);
                      setSelectedStudent(value);
                    }}
                    options={dataStudents?.data ?? []}
                    getOptionLabel={(option) => option?.ChildName}
                    popupIcon={<IconDownArrow />}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option?.ChildName}
                        </li>
                      );
                    }}
                    value={selectedStudent}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Student"
                        variant="outlined"
                        error={!!errors.category?.message}
                        required
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>{(errors as any)?.Batch?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              {...register("Height", {
                required: "This field is required",
                valueAsNumber: true,
              })}
              error={!!(errors as any)?.Height}
              helperText={(errors as any)?.Height?.message}
              margin="normal"
              fullWidth
              type="number"
              label="Height (in cm)"
              name="Height"
              required
              InputProps={{
                inputProps: { min: 0 },
              }}
              onWheel={blurTarget}
              onKeyPress={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              {...register("Weight", {
                required: "This field is required",
                valueAsNumber: true,
              })}
              error={!!(errors as any)?.Weight}
              helperText={(errors as any)?.Weight?.message}
              margin="normal"
              fullWidth
              type="number"
              label="Weight (in KG)"
              name="Weight"
              required
              InputProps={{
                inputProps: { min: 0 },
              }}
              onWheel={blurTarget}
              onKeyPress={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              {...register("Temperature", {
                required: "This field is required",
                valueAsNumber: true,
              })}
              error={!!(errors as any)?.Temperature}
              helperText={(errors as any)?.Temperature?.message}
              margin="normal"
              fullWidth
              type="number"
              label="Temperature (in deg cel)"
              name="Temperature"
              required
              InputProps={{
                inputProps: { min: 0 },
              }}
              onWheel={blurTarget}
              onKeyPress={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  );
};
