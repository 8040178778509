import { Typography, Box } from "@mui/material";

const PageHeading = ({ heading }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: 2,
        mb: 3,
      }}
    >
      <Typography variant="h5" component="h2" fontWeight={600}>
        {heading}
      </Typography>
    </Box>
  );
};

export default PageHeading;
