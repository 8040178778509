import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Box } from "@mui/material";

type T = {
  slug: string;
  heading: string;
  icon: any;
};

const DailyActivities = ({
  items,
  title,
  borderbottom,
}: {
  items: Array<T>;
  title: string;
  borderbottom: boolean;
}) => {
  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{
        mt: 2,
        boxShadow: 0,
        mb: 3,
        pb: 1,
        border: 0,
        borderRadius: 0,
        borderBottom: borderbottom ? "1px #aaa solid" : "1px transparent solid",
      }}
    >
      <CardContent sx={{ boxShadow: 0 }}>
        <Typography
          sx={{ fontSize: 20, mb: 3, fontWeight: "bold" }}
          gutterBottom
          color="text.primary"
        >
          {title}
        </Typography>
        <Grid container rowSpacing={3} columnSpacing={6}>
          {items.map((item: any) => (
            <Grid item xs={12} md={6} lg={3} key={uuidv4()}>
              <Link
                to={item.slug}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: 4,
                    px: 1,
                    py: 8,
                    backgroundColor: "#f8b86499",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {item.icon}
                  <Typography
                    fontSize={16}
                    variant="body2"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {item.heading}
                  </Typography>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default DailyActivities;
