import { RefineThemes } from "@refinedev/mui";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#f8b864",
    },
    secondary: {
      main: "#f8b864",
    },
  },
});

const DarkTheme = createTheme({
  palette: {
    ...RefineThemes.BlueDark.palette,
    primary: {
      main: "#f8b864",
    },
    secondary: {
      main: "#f8b864",
    },
  },
});

const DarkThemeWithResponsiveFontSizes = responsiveFontSizes(DarkTheme);
const LightThemeWithResponsiveFontSizes = responsiveFontSizes(customTheme);

export { LightThemeWithResponsiveFontSizes, DarkThemeWithResponsiveFontSizes };
