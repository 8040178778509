import { useTheme } from "@mui/material/styles";

const IconDashboard = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const fill = mode === "dark" ? "#fff" : "#303030";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
      <path
        fill={fill}
        d="M1 10h6c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1H1C.45 0 0 .45 0 1v8c0 .55.45 1 1 1Zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H1c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1Zm10 0h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1ZM10 1v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1Z"
      />
    </svg>
  );
};
export default IconDashboard;
